import actionTypes from "../actions/actionTypes";

const initialState = {
    count: 0,
    pages: 0,
    page: 0,
    bids: [],

    bidDetails: null,
    statusMessage: null
}

const bidReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.BIDS.GET_BIDS_SUCCESS:
            return {
                ...state,
                count: payload?.count,
                pages: payload?.pages,
                page: payload?.page,
                bids: payload?.bids,
                statusMessage: actionTypes.BIDS.GET_BIDS_SUCCESS
            }

        case actionTypes.BIDS.GET_BIDS_FAILED:
            return {
                ...state,
                count: 0,
                pages: 0,
                page: 0,
                bids: [],
                statusMessage: actionTypes.BIDS.GET_BIDS_FAILED
            }

        case actionTypes.BIDS.GET_BID_DETAILS_SUCCESS:
            return {
                ...state,
                bidDetails: payload,
                statusMessage: actionTypes.BIDS.GET_BID_DETAILS_SUCCESS
            }

        case actionTypes.BIDS.GET_BID_DETAILS_FAILED:
            return {
                ...state,
                bidDetails: null,
                statusMessage: actionTypes.BIDS.GET_BID_DETAILS_FAILED
            }

            case actionTypes.CLEAR_STATUS_MESSAGE:
                return {...state, statusMessage:null}

        default: return state
    }
}

export default bidReducer;