import actionTypes from "../actions/actionTypes";

const initialState = {
    logged__in: false,
    admin__details: null,

    status__message: null
}


const adminReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.ADMIN.CHECK_LOGIN_STATUS:
            return { ...state, logged__in: payload }

        case actionTypes.ADMIN.ADMIN_SIGN_IN_SUCCESS:
            return {
                ...state,
                logged__in: true,
                status__message: actionTypes.ADMIN.ADMIN_SIGN_IN_SUCCESS
            }

        case actionTypes.ADMIN.ADMIN_SIGN_IN_FAILED:
            return {
                ...state,
                logged__in: false,
                status__message: actionTypes.ADMIN.ADMIN_SIGN_IN_FAILED
            }

        case actionTypes.ADMIN.ADMIN_SIGN_OUT:
            return {
                ...state,
                logged__in: false,
                admin__details: null,
                status__message: actionTypes.ADMIN.ADMIN_SIGN_OUT
            }

        case actionTypes.ADMIN.ADMIN_DETAILS_SUCCESS:
            return {
                ...state,
                status__message: actionTypes.ADMIN.ADMIN_DETAILS_SUCCESS,
                admin__details: payload
            }

        case actionTypes.ADMIN.ADMIN_DETAILS_FAILED:
            return {
                ...state,
                status__message: actionTypes.ADMIN.ADMIN_DETAILS_FAILED,
                admin__details: null
            }

        case actionTypes.ADMIN.UPDATE_PROFILE_SUCCESS:
            return { ...state, status__message: actionTypes.ADMIN.UPDATE_PROFILE_SUCCESS }

        case actionTypes.ADMIN.UPDATE_PROFILE_FAILED:
            return { ...state, status__message: actionTypes.ADMIN.UPDATE_PROFILE_FAILED }

            case actionTypes.ADMIN.UPDATE_DETAILS_SUCCESS:
            return { ...state, status__message: actionTypes.ADMIN.UPDATE_DETAILS_SUCCESS }

        case actionTypes.ADMIN.UPDATE_DETAILS_FAILED:
            return { ...state, status__message: actionTypes.ADMIN.UPDATE_DETAILS_FAILED }

        case actionTypes.CLEAR_STATUS_MESSAGE:
            return { ...state, status__message: null }

        default: return state;
    }
}

export default adminReducer;