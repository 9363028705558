import actionTypes from "../actions/actionTypes"

const initialState = {
    analytics: [],
    w: "",
    y: "",

    status__message: null
}

const analyticReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.ANALYTICS.GET_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: payload.analytics,
                website: payload.w,
                year: payload.y,
                status__message: actionTypes.ANALYTICS.GET_ANALYTICS_SUCCESS
            }

        case actionTypes.ANALYTICS.GET_ANALYTICS_FAILED:
            return {
                ...state,
                analytics: [],
                website: payload.w,
                year: payload.y,
                status__message: actionTypes.ANALYTICS.GET_ANALYTICS_FAILED
            }

        default: return state;
    }
}

export default analyticReducer;