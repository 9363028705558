import actionTypes from "../actions/actionTypes";

const initialState = {
    templates: [],

    status__message: null
}

const emailReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.EMAIL.GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: payload.templates,
                page: payload.page,
                pages: payload.pages,

                selectrow: payload.selectrow,
                name: payload.name,
                type: payload.type,
                category: payload.category,
                websitecode: payload.websitecode,

                status__message: actionTypes.EMAIL.GET_TEMPLATES_SUCCESS
            }

        case actionTypes.EMAIL.GET_TEMPLATES_FAILED:
            return {
                ...state,
                templates: [],
                page: 1,
                pages: 1,

                selectrow: payload.selectrow,
                name: payload.name,
                type: payload.type,
                category: payload.category,
                websitecode: payload.websitecode,

                status__message: actionTypes.EMAIL.GET_TEMPLATES_FAILED
            }

        case actionTypes.EMAIL.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                status__message: actionTypes.EMAIL.SEND_EMAIL_SUCCESS
            }

        case actionTypes.EMAIL.SEND_EMAIL_FAILED:
            return {
                ...state,
                status__message: actionTypes.EMAIL.SEND_EMAIL_FAILED
            }

        case actionTypes.CLEAR_STATUS_MESSAGE:
            return { ...state, status__message: null }

        default: return state;
    }
}

export default emailReducer;