import axios from "../../api/axios";
import { toast } from "react-toastify";
import actionTypes from "./actionTypes";

// Check login status
export const checkLoginStatus = () => ({
  type: actionTypes.ADMIN.CHECK_LOGIN_STATUS,
  payload:
    localStorage.getItem("_token") &&
    localStorage.getItem("_role") === "subadmin"
      ? true
      : false,
});

// Admin login
export const adminSignIn = (data) => async (dispatch) => {
  await axios
    .post("/api/assignmentadmin/login", data)
    .then((response) => {
      if (response?.data?.admin?.role === "subadmin") {
        localStorage.setItem("_token", response?.data?.token);
        localStorage.setItem("_id", response?.data?.admin?._id);
        localStorage.setItem("_email", response?.data?.admin?.email);
        localStorage.setItem("_role", response?.data?.admin?.role);
        localStorage.setItem("_websites", response?.data?.admin?.website_code);

        toast.success("Login credentials accepted!");
        dispatch({ type: actionTypes.ADMIN.ADMIN_SIGN_IN_SUCCESS });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch({ type: actionTypes.ADMIN.ADMIN_SIGN_IN_FAILED });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};

// Admin logout
export const adminSignOut = () => (dispatch) => {
  localStorage.clear();
  dispatch({ type: actionTypes.ADMIN.ADMIN_SIGN_OUT });

  setTimeout(() => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }), 1000);
};

// Admin details
export const adminDetails = (signal) => async (dispatch) => {
  const id = localStorage.getItem("_id");
  const token = localStorage.getItem("_token");

  await axios
    .get(`/api/assignmentadmin/${id}`, {
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response?.data?.admin?.role === "subadmin") {
        dispatch({
          type: actionTypes.ADMIN.ADMIN_DETAILS_SUCCESS,
          payload: response?.data?.admin,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch({ type: actionTypes.ADMIN.ADMIN_DETAILS_FAILED });

      dispatch(adminSignOut());
    });
};

// Update profile
export const updateProfile = (file) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  const data = new FormData();
  data.append("profile_pic", file);

  await axios
    .patch("/api/assignmentadmin/update/profilePic", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        toast.success("Profile updated successfully!");
        dispatch({ type: actionTypes.ADMIN.UPDATE_PROFILE_SUCCESS });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch({ type: actionTypes.ADMIN.UPDATE_PROFILE_FAILED });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};

// Update details
export const updateDetails = (data) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  await axios
    .patch(`/api/assignmentadmin/update/details`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        toast.success("Details updated successfully!");
        dispatch({ type: actionTypes.ADMIN.UPDATE_DETAILS_SUCCESS });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch({ type: actionTypes.ADMIN.UPDATE_DETAILS_SUCCESS });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};
