import { combineReducers } from "redux";

import adminReducer from "./adminReducer";
import orderStageReducer from "./orderStageReducer";
import messageReducer from "./messageReducer";
import userReducer from "./userReducer";
import orderReducer from "./orderReducer";
import emailReducer from "./emailReducer";
import notificationReducer from "./notificationReducer";
import analyticReducer from "./analyticReducer";
import bidReducer from "./bidReducer";

const rootReducer = combineReducers({
    admin: adminReducer,
    stage: orderStageReducer,
    order: orderReducer,
    email: emailReducer,
    message: messageReducer,
    user: userReducer,
    notification: notificationReducer,
    analytics: analyticReducer,
    bids: bidReducer
});

export default rootReducer;