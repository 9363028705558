const actionTypes = {
    ADMIN: {
        CHECK_LOGIN_STATUS: "CHECK_LOGIN_STATUS",

        ADMIN_SIGN_IN_SUCCESS: "ADMIN_SIGN_IN_SUCCESS",
        ADMIN_SIGN_IN_FAILED: "ADMIN_SIGN_IN_FAILED",

        ADMIN_SIGN_OUT: "ADMIN_SIGN_OUT",

        ADMIN_DETAILS_SUCCESS: "ADMIN_DETAILS_SUCCESS",
        ADMIN_DETAILS_FAILED: "ADMIN_DETAILS_FAILED",

        UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
        UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",

        UPDATE_DETAILS_SUCCESS: "UPDATE_DETAILS_SUCCESS",
        UPDATE_DETAILS_FAILED: "UPDATE_DETAILS_FAILED",
    },

    STAGE: {
        GET_STAGES_SUCCESS: "GET_STAGES_SUCCESS",
        GET_STAGES_FAILED: "GET_STAGES_FAILED",
    },

    ORDER: {
        GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
        GET_ORDERS_FAILED: "GET_ORDERS_FAILED",

        HANDLE_CHECKBOX: "HANDLE_CHECKBOX",
        REMOVE_SELECTED_ORDER: "REMOVE_SELECTED_ORDER",

        SET_PRIORITY_SUCCESS: "SET_PRIORITY_SUCCESS",
        SET_PRIORITY_FAILED: "SET_PRIORITY_FAILED",

        READ_ORDER_SUCCESS: "READ_ORDER_SUCCESS",
        READ_ORDER_FAILED: "READ_ORDER_FAILED",

        ADD_ORDER_SUCCESS: "ADD_ORDER_SUCCESS",
        ADD_ORDER_FAILED: "ADD_ORDER_FAILED",
        EDIT_ORDER_SUCCESS: "EDIT_ORDER_SUCCESS",
        EDIT_ORDER_FAILED: "EDIT_ORDER_FAILED",

        UPDATE_ORDER_STATUS_SUCCESS: "UPDATE_ORDER_STATUS_SUCCESS",
        UPDATE_ORDER_STATUS_FAILED: "UPDATE_ORDER_STATUS_FAILED",

        GET_ORDER_DETAILS_SUCCESS: "GET_ORDER_DETAILS_SUCCESS",
        GET_ORDER_DETAILS_FAILED: "GET_ORDER_DETAILS_FAILED",
        REMOVE_ORDER_DETAILS: "REMOVE_ORDER_DETAILS",

        SEARCH_ORDER_BY_ID_SUCCESS: "SEARCH_ORDER_BY_ID_SUCCESS",
        SEARCH_ORDER_BY_ID_FAILED: "SEARCH_ORDER_BY_ID_FAILED",
        REMOVE_SEARCH_ORDER_BY_ID: "REMOVE_SEARCH_ORDER_BY_ID",

        ORDER_TIMELINE_SUCCESS: "ORDER_TIMELINE_SUCCESS",
        ORDER_TIMELINE_FAILED: "ORDER_TIMELINE_FAILED",
        REMOVE_ORDER_TIMELINE: "REMOVE_ORDER_TIMELINE",

        ORDER_CALL_TIMELINE_SUCCESS: "ORDER_CALL_TIMELINE_SUCCESS",
        ORDER_CALL_TIMELINE_FAILED: "ORDER_CALL_TIMELINE_FAILED",
        REMOVE_ORDER_CALL_TIMELINE: "REMOVE_ORDER_CALL_TIMELINE",

        GET_SOLUTIONS_SUCCESS: "GET_SOLUTIONS_SUCCESS",
        GET_SOLUTIONS_FAILED: "GET_SOLUTIONS_FAILED",
        REMOVE_SOLUTIONS: "REMOVE_SOLUTIONS"
    },

    EMAIL: {
        GET_TEMPLATES_SUCCESS: "GET_TEMPLATES_SUCCESS",
        GET_TEMPLATES_FAILED: "GET_TEMPLATES_FAILED",

        SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
        SEND_EMAIL_FAILED: "SEND_EMAIL_FAILED",
    },

    MESSAGE: {
        MESSAGE_LIST_SUCCESS: "MESSAGE_LIST_SUCCESS",
        MESSAGE_LIST_FAILED: "MESSAGE_LIST_FAILED",

        ROOM_MESSAGE_SUCCESS: "ROOM_MESSAGE_SUCCESS",
        ROOM_MESSAGE_FAILED: "ROOM_MESSAGE_FAILED",
        REMOVE_ROOM_MESSAGE: "REMOVE_ROOM_MESSAGE",

        MARK_MESSAGE_READ_SUCCESS: "MARK_MESSAGE_READ_SUCCESS",
        MARK_MESSAGE_READ_FAILED: "MARK_MESSAGE_READ_FAILED",

        EDIT_MESSAGE_SUCCESS: "EDIT_MESSAGE_SUCCESS",
        EDIT_MESSAGE_FAILED: "EDIT_MESSAGE_FAILED",

        DELETE_MESSAGE_SUCCESS: "DELETE_MESSAGE_SUCCESS",
        DELETE_MESSAGE_FAILED: "DELETE_MESSAGE_FAILED"
    },

    ORDER_NOTIFICATION: {
        GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
        GET_NOTIFICATIONS_FAILED: "GET_NOTIFICATIONS_FAILED",
        GET_NOTIFICATIONS_LIST_SUCCESS: "GET_NOTIFICATIONS_LIST_SUCCESS",
        GET_NOTIFICATIONS_LIST_FAILED: "GET_NOTIFICATIONS_LIST_FAILED",

        HANDLE_NOTIFICATION_CHECKBOX: "HANDLE_NOTIFICATION_CHECKBOX",
        REMOVE_SELECTED_NOTIFICATION: "REMOVE_SELECTED_NOTIFICATION",
    },

    MESSAGE_NOTIFICATION: {
        GET_MESSAGE_NOTIFICATIONS_SUCCESS: "GET_MESSAGE_NOTIFICATIONS_SUCCESS",
        GET_MESSAGE_NOTIFICATIONS_FAILED: "GET_MESSAGE_NOTIFICATIONS_FAILED"
    },

    USER: {
        GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
        GET_USERS_FAILED: "GET_USERS_FAILED",

        GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
        GET_USER_DETAILS_FAILED: "GET_USER_DETAILS_FAILED",
        REMOVE_USER_DETAILS: "REMOVE_USER_DETAILS",
    },

    ANALYTICS: {
        GET_ANALYTICS_SUCCESS: "GET_ANALYTICS_SUCCESS",
        GET_ANALYTICS_FAILED: "GET_ANALYTICS_FAILED",

        REMOVE_ANALYTICS: "REMOVE_ANALYTICS",
    },

    BIDS: {
        GET_BIDS_SUCCESS: "GET_BIDS_SUCCESS",
        GET_BIDS_FAILED: "GET_BIDS_FAILED",

        GET_BID_DETAILS_SUCCESS:"GET_BID_DETAILS_SUCCESS",
        GET_BID_DETAILS_FAILED:"GET_BID_DETAILS_FAILED"
    },

    CLEAR_STATUS_MESSAGE: "CLEAR_STATUS_MESSAGE"
}

export default actionTypes;