import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LoadingImage from "./assets/images/loading.gif";

import { checkLoginStatus } from "./redux/actions/adminActions";

const Header = lazy(() => import("./components/Header/Header"));
const SignIn = lazy(() => import("./pages/SignIn/SignIn"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const NewOrder = lazy(() => import("./pages/Orders/NewOrder"));
const EditOrder = lazy(() => import("./pages/Orders/EditOrder"));
const OrderDetails = lazy(() => import("./pages/Details/OrderDetails"));
const Users = lazy(() => import("./pages/Users/Users"));
const Conversation = lazy(() => import("./pages/Conversations/Conversation"));
const OrderNotifications = lazy(() => import("./pages/Notifications/OrderNotifications"));
const Analytics = lazy(() => import("./pages/Analytics/Analytics"));
const OpenOrders = lazy(() => import("./pages/OpenOrders/OpenOrders"));
const Biddings = lazy(() => import("./pages/Biddings"));



// Suspense fallback
const SuspenseFallback = () => {
  return (
    <div className="h-screen w-screen bg-transparent flex justify-center items-center">
      <img src={LoadingImage} className="w-20 h-20" />
    </div>
  );
};



function App() {
  const dispatch = useDispatch();



  // Check login status
  const loginStatus = useSelector((state) => state.admin.logged__in);

  useEffect(() => {
    dispatch(checkLoginStatus());
  }, []);



  // Request notifications permission
  useEffect(() => {
    const askPermission = async () => {
      if (navigator.platform.startsWith("Win")) await Notification.requestPermission();
      if (navigator.platform.startsWith("Mac")) await Notification.requestPermission();
    }

    setTimeout(() => {
      askPermission();
    }, 2000)
  }, [])



  // Authentication
  const Authentication = () => {
    const token = localStorage.getItem("_token");
    const role = localStorage.getItem("_role");

    return token && role === "subadmin" ? <Outlet /> : <Navigate to="/" />;
  };



  return (
    <Suspense fallback={<SuspenseFallback />}>
      <BrowserRouter>
        {loginStatus && <Header />}
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/open-orders" element={<OpenOrders />} />

          <Route path="/dashboard" element={<Authentication />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route path="/dashboard/:query" element={<Authentication />}>
            <Route path="/dashboard/:query" element={<Dashboard />} />
          </Route>
          <Route path="/orders/new-order" element={<Authentication />}>
            <Route path="/orders/new-order" element={<NewOrder />} />
          </Route>
          <Route path="/orders/edit-order/:id" element={<Authentication />}>
            <Route path="/orders/edit-order/:id" element={<EditOrder />} />
          </Route>
          <Route path="/orders/order-details/:query" element={<Authentication />} >
            <Route path="/orders/order-details/:query" element={<OrderDetails />} />
          </Route>

          <Route path="/conversations" element={<Authentication />}>
            <Route path="/conversations" element={<Conversation />} />
          </Route>
          <Route path="/conversations/:query" element={<Authentication />} >
            <Route path="/conversations/:query" element={<Conversation />} />
          </Route>

          <Route path="/dashboard/users" element={<Authentication />}>
            <Route path="/dashboard/users" element={<Users />} />
          </Route>

          <Route path="/notifications/order-notifications" element={<Authentication />}>
            <Route path="/notifications/order-notifications" element={<OrderNotifications />} />
          </Route>

          <Route path="/orders/analytics" element={<Authentication />}>
            <Route path="/orders/analytics" element={<Analytics />} />
          </Route>
          <Route path="/orders/analytics/:query" element={<Authentication />}>
            <Route path="/orders/analytics/:query" element={<Analytics />} />
          </Route>

          <Route path="/biddings" element={<Authentication />}>
            <Route path="/biddings" element={<Biddings />} />
          </Route>
          <Route path="/biddings/:query" element={<Authentication />}>
            <Route path="/biddings/:query" element={<Biddings />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
