import actionTypes from "../actions/actionTypes";

const initialState = {
    status__message: null,

    notifications: [],
    count: 0,

    list__count:0,
    notifications__list: [],
    page: 1,
    total__pages: 1,
    seen: "",

    selected: [],

    message__notifications: [],
    message__count: 0
}

const notificationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: payload.notifications,
                count: payload.count,
                status__message: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_SUCCESS
            }

        case actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_FAILED:
            return {
                ...state,
                notifications: [],
                count: 0,
                status__message: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_FAILED
            }

        case actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_LIST_SUCCESS:
            return {
                ...state,
                notifications__list: payload.notifications,
                page: payload.page,
                seen: payload.seen,
                total__pages: payload.totalPages,
                list__count:payload.count,
                status__message: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_LIST_SUCCESS
            }

        case actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_LIST_FAILED:
            return {
                ...state,
                notifications__list: [],
                page: 1,
                seen: payload,
                total__pages: 1,
                list__count:0,
                status__message: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_LIST_FAILED
            }

        case actionTypes.ORDER_NOTIFICATION.HANDLE_NOTIFICATION_CHECKBOX:
            return {
                ...state,
                notifications__list: payload.notifications,
                selected: payload.selected
            }

        case actionTypes.ORDER_NOTIFICATION.REMOVE_SELECTED_NOTIFICATION:
            return {
                ...state,
                selected: []
            }

        case actionTypes.MESSAGE_NOTIFICATION.GET_MESSAGE_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                message__notifications: payload.notifications,
                message__count: payload.count,
                status__message: actionTypes.MESSAGE_NOTIFICATION.GET_MESSAGE_NOTIFICATIONS_SUCCESS
            }

        case actionTypes.MESSAGE_NOTIFICATION.GET_MESSAGE_NOTIFICATIONS_FAILED:
            return {
                ...state,
                message__notifications: [],
                message__count: 0,
                status__message: actionTypes.MESSAGE_NOTIFICATION.GET_MESSAGE_NOTIFICATIONS_FAILED
            }

        case actionTypes.CLEAR_STATUS_MESSAGE:
            return { ...state, status__message: null }

        default: return state;
    }
}

export default notificationReducer;