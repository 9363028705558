import actionTypes from "../actions/actionTypes";

const initialState = {
    users: [],
    page: 1,
    pages: 1,
    select__row: 25,
    website__code: "",

    user: null,

    status__message: null
}

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.USER.GET_USERS_SUCCESS:
            return {
                ...state,
                users: payload.users,
                page: payload.currentPage,
                pages: payload.totalPages,
                select__row: payload.selectrow,
                website__code: payload.websitecode,
                status__message: actionTypes.USER.GET_USERS_SUCCESS
            }

        case actionTypes.USER.GET_USERS_FAILED:
            return {
                ...state,
                users: [],
                page: 1,
                pages: 1,
                select__row: payload.selectrow,
                website__code: payload.websitecode,
                status__message: actionTypes.USER.GET_USERS_FAILED
            }

        case actionTypes.USER.GET_USER_DETAILS_SUCCESS:
            return { ...state, user: payload, }

        case actionTypes.USER.GET_USER_DETAILS_FAILED:
            return { ...state, user: null }

        case actionTypes.USER.REMOVE_USER_DETAILS:
            return { ...state, user: null }

        case actionTypes.CLEAR_STATUS_MESSAGE:
            return { ...state, status__message: null }

        default: return state;
    }
}

export default userReducer;