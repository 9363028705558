import actionTypes from "../actions/actionTypes";

const initialState = {
    stages: [],

    status__message: null
}

const orderStageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.STAGE.GET_STAGES_SUCCESS:
            return {
                ...state,
                stages: payload.stages,
                page: payload.page,
                pages: payload.pages,
                select__row: payload.selectRow,
                status__message: actionTypes.STAGE.GET_STAGES_SUCCESS
            }

        case actionTypes.STAGE.GET_STAGES_FAILED:
            return {
                ...state,
                stages: [],
                page: 1,
                pages: 1,
                select__row: payload,
                status__message: actionTypes.STAGE.GET_STAGES_FAILED
            }

        default: return state;
    }
}

export default orderStageReducer;